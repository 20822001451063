$gd-event-status-in-progress: $warning;
$gd-event-status-completed: $success;
$gd-event-status-suspended: #ff5722;
$gd-event-status-cancelled: $danger;

@keyframes blink {
  from {
    background-color: $danger;
  }
  to {
    background-color: $yellow;
  }
}

.gd-events {
  font-size: 0.9em;
}

.event-cell {
  cursor: default;
  opacity: 0.3;
  position: relative;
  width: 18px;
  height: 18px;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px dashed $gray-500;
    border-radius: $border-radius;
  }
}

.event {
  &__status {
    position: relative;
    display: inline-block;
    padding: 2px 6px;
    background-color: $gray-600;
    color: $gray-100;
    font-size: 0.9em;
    font-weight: bold;
    border-radius: $border-radius;
    letter-spacing: 0.03em;
    width: 100px;
    text-align: center;
    border: 0;
    user-select: none;

    &--small {
      padding: 0;
      width: 18px;
      height: 18px;
      font-size: 0.85em;
      line-height: 17px;

      & + & {
        margin-top: 2px;
      }
    }

    &--compact {
      width: 85px;
      padding: 1px 3px;
      font-size: 0.8em;
    }

    &--full-width {
      width: 100%;
    }

    &:focus {
      outline: 0;
    }

    &--in_progress {
      background-color: $gd-event-status-in-progress;
      color: $white;
    }

    &--completed {
      background-color: $gd-event-status-completed;
      color: lighten($gd-event-status-completed, 60);
    }

    &--suspended {
      background-color: lighten($gd-event-status-suspended, 10);
      color: lighten($gd-event-status-suspended, 60);
    }

    &--cancelled,
    &--rejected {
      background-color: $gd-event-status-cancelled;
      color: lighten($gd-event-status-cancelled, 60);
    }

    &--no-machine {
      position: relative;
      padding-top: 0;
      padding-bottom: 4px;

      &::before {
        position: absolute;
        content: '';
        z-index: 0;
        right: -4px;
        bottom: -4px;
        height: 10px;
        width: 10px;
        background-color: red;
        border-radius: 100%;
        border: 2px solid $light;
      }
    }

    &--forbidden {
      cursor: not-allowed;
    }
  }

  &__progress {
    height: 100%;
    background-color: $gray-600;
    opacity: 0.15;
    position: absolute;
    left: 0;
    top: 0;
    transition-property: width;
    transition-duration: 1s;
  }
}
