$gd-issue-status-opened: $gray-300;
$gd-issue-status-resolved: $success;
$gd-issue-status-closed: $danger;

.issue {
  &__status {
    display: inline-block;
    padding: 4px;
    border: 1px solid $gd-issue-status-opened;
    background-color: $white;
    color: $gray-600;
    font-size: 0.8em;
    font-weight: bold;
    border-radius: $border-radius * 4;
    letter-spacing: 0.03em;
    width: 90px;
    text-align: center;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;

    &:focus {
      outline: 0;
    }

    &--small {
      font-size: 0.7rem;
      padding: 2px;
      width: 80px;
    }

    &--resolved {
      background-color: $gd-issue-status-resolved;
      border-color: transparent;
      color: $white;
    }

    &--closed {
      background-color: $gd-issue-status-closed;
      border-color: transparent;
      color: $white;
    }
  }
}
