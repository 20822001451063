.gd-loading {
  height: 50vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-lg;
  font-size: 3rem;
  color: $gray-500;
  position: relative;

  &__tbody {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.gd-loading__table-fill {
  display: block;
  background-color: $gray-200;
  border-radius: $border-radius-pill;
  position: relative;

  &:before {
    content: '\0000a0';
  }
}
