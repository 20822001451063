.gd-due-date {
  $parent: &;
  @extend .small;

  display: inline-block;
  padding: 3px 8px;
  color: $gray-700;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $box-shadow-lg;
  border: 1px solid transparent;
  font-weight: 500;

  &--tooltip > span {
    border-bottom: 2px dotted $gray-500;
  }

  &--upcoming {
    // background-color: lighten($yellow, 30);
    color: darken($warning, 25);
    border-color: $warning;
    background-color: rgba($warning, 0.05);
    @include shadow($warning);

    & > span {
      border-color: darken($warning, 5);
    }
  }

  &--expired {
    color: $danger;
    border-color: $danger;
    background-color: rgba($danger, 0.05);
    @include shadow($danger);

    & > span {
      border-color: lighten($danger, 15);
    }
  }

  &--finished {
    color: $gray-500;

    & > span {
      border-color: $gray-300;
    }
  }
}
