$gd-time-entry-prepare: $info;
$gd-time-entry-work: $warning;
$gd-time-entry-break: $secondary;
$gd-time-entry-maintenance: #6746d3;
$gd-time-entry-out-of-stock: $orange;
$gd-time-entry-device-failure: $danger;
$gd-time-entry-other: #333;

.rct-item-content {
  padding: 4px 3px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gd-time-table {
  &__item-container {
    background-color: $white;
    border-radius: $border-radius;
  }

  &__item {
    border: 1px solid $gray-500 !important;
    background-color: $gray-300 !important;
    border-radius: $border-radius;
    color: $gray-700 !important;
    font-size: 0.8em;
    font-weight: 600;
    border-radius: 2px;
    line-height: 10px;
    display: flex;
    align-items: center;

    &--label {
      width: 12px;
      height: 12px;
      border-radius: 2px;
      margin-right: 4px;
    }

    &--prepare {
      border-color: $gd-time-entry-prepare !important;
      background-color: rgba($gd-time-entry-prepare, 0.5) !important;
      color: darken($gd-time-entry-prepare, 20) !important;
    }

    &--work {
      border-color: $gd-time-entry-work !important;
      background-color: rgba($gd-time-entry-work, 0.5) !important;
      color: darken($gd-time-entry-work, 30) !important;
    }

    &--break {
      border-color: $gd-time-entry-break !important;
      background-color: rgba($gd-time-entry-break, 0.35) !important;
    }

    &--maintenance {
      border-color: $gd-time-entry-maintenance !important;
      background-color: rgba($gd-time-entry-maintenance, 0.5) !important;
      color: darken($gd-time-entry-maintenance, 30) !important;
    }

    &--out_of_stock {
      border-color: $gd-time-entry-out-of-stock !important;
      background-color: rgba($gd-time-entry-out-of-stock, 0.5) !important;
      color: darken($gd-time-entry-out-of-stock, 30) !important;
    }

    &--device_failure {
      border-color: $gd-time-entry-device-failure !important;
      background-color: rgba($gd-time-entry-device-failure, 0.5) !important;
      color: darken($gd-time-entry-device-failure, 35) !important;
    }

    &--other {
      border-color: $gd-time-entry-other !important;
      background-color: rgba($gd-time-entry-other, 0.6) !important;
      color: lighten($gd-time-entry-other, 100) !important;
    }
  }

  &__item-text {
    &--prepare {
      color: darken($gd-time-entry-prepare, 20) !important;
    }

    &--work {
      color: darken($gd-time-entry-work, 30) !important;
    }

    &--maintenance {
      color: darken($gd-time-entry-maintenance, 30) !important;
    }

    &--out_of_stock {
      color: darken($gd-time-entry-out-of-stock, 30) !important;
    }

    &--device_failure {
      color: darken($gd-time-entry-device-failure, 35) !important;
    }
  }

  &__group {
    position: relative;
    background-color: rgba($gray-400, 0.8);
    color: $gray-800;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1em;
    font-size: 0.8em;
    font-weight: 600;

    &:after {
      position: absolute;
      content: '';
      right: -3px;
      top: 0;
      width: 2px;
      height: 100%;
      background-color: $gray-500;
    }
  }

  &__long-date {
    background-color: $gray-400;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    font-weight: 600;
    color: $gray-700;
  }

  &__short-date {
    background-color: $gray-200;
    display: flex;
    align-items: center;
    font-size: 0.7em;
    font-weight: 600;
    color: $gray-600;
  }

  &__marker {
    background-color: transparent !important;
    border-right: 2px dashed $primary;
  }
}

.rct-sidebar-row-even {
  .gd-time-table__group {
    background-color: $gray-200;
  }
}

.rct-hl-even {
  background-color: white !important;
  border-bottom-color: $gray-400 !important;
}

.rct-hl-odd {
  background-color: rgba($gray-200, 0.6) !important;
  border-bottom-color: $gray-400 !important;
}

.rct-vl {
  border-left-color: $gray-300 !important;
}

.rct-day-0,
.rct-day-6 {
  background-color: rgba($danger, 0.05) !important;
}

.time-table-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40vh;
}
