:root {
  --toastify-toast-min-height: 32px;
}

.Toastify__toast {
  border-radius: $border-radius;
  box-shadow: rgba($black, 0.1) 0 5px 15px;
}

.gd-toast {
  $parent: &;

  color: $gray-600;
  font-size: 0.85rem;
  z-index: 99999;

  &__header {
    padding-bottom: calc($spacer / 3);
    margin-bottom: calc($spacer / 2);
    border-bottom: 1px solid rgba($dark, 0.1);
    font-weight: 600;
  }

  &__order-number {
    float: right;
    margin-right: calc($spacer / 2);
    margin-top: 1px;
    background-color: $gray-200;
    padding: 0 6px;
    border-radius: $border-radius;
    font-weight: 600;
    color: $gray-600;
    font-size: 0.8rem;
  }

  &--user {
    .gd-user-badge__avatar {
      width: 20px;
      height: 20px;
      margin-right: calc($spacer / 2);
    }
  }

  &--info {
    #{$parent}__header {
      color: $info;
    }
  }
  &--bs-warning {
    #{$parent}__header {
      color: $danger;
    }
  }
}
