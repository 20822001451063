.timeline {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  background: $gray-400;
  left: 20%;
  margin-left: -2.5px
}

.timeline>li {
  position: relative;
  min-height: 50px;
  padding: 10px 0;
  margin: 0;
}

.timeline .timeline-time {
  position: absolute;
  left: 0;
  width: 18%;
  text-align: right;
  top: 30px
}

.timeline .timeline-time .date,
.timeline .timeline-time .time {
  display: block;
}

.timeline .timeline-time .time {
  line-height: 24px;
  font-size: 20px;
}

.timeline .timeline-icon {
  left: 15%;
  position: absolute;
  width: 10%;
  text-align: center;
  top: 40px
}

.timeline .timeline-icon span {
  text-decoration: none;
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 20px;
  background: $gray-500;
  line-height: 10px;
  color: #fff;
  font-size: 14px;
  position: relative;
  left: -1px;
}

.timeline .timeline-body {
  margin-left: 23%;
  margin-right: 17%;
  background: #fff;
  position: relative;
  padding: 20px 25px;
  border-radius: 6px
}

.timeline .timeline-body:before {
  content: '';
  display: block;
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #fff;
  left: -20px;
  top: 20px
}

.timeline .timeline-body>div+div {
  margin-top: 15px
}

.timeline .timeline-body>div+div:last-child {
  margin-bottom: -20px;
  padding-bottom: 20px;
  border-radius: 0 0 6px 6px
}

.timeline-header {
  padding-bottom: 10px;
  border-bottom: 1px solid $light;
  line-height: 30px
}

.timeline img {
  max-width: 100%;
  display: block
}

.timeline-content {
  letter-spacing: .25px;
  line-height: 18px;
  font-size: 13px
}

.timeline-content:after,
.timeline-content:before {
  content: '';
  display: table;
  clear: both
}

.timeline-title {
  margin-top: 0
}
