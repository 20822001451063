.event-badge-container {
  display: inline-flex;
  align-items: center;
}

.event-badge {
  display: inline-flex;
  $parent: &;

  border: 0;
  border-radius: 20px;
  font-size: 0.8rem;
  padding: 0;
  margin: 0 1px;
  user-select: none;
  background-color: $gray-200;

  &__position {
    display: inline-block;
    width: 16px;
    margin-right: 2px;
    border-radius: 100%;
    background-color: $gray-600;
    color: $gray-100;
    font-weight: bold;
  }

  &__text {
    display: inline-block;
    padding: 0 6px 0 2px;
    max-width: 70px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.75rem;
  }

  &__line {
    display: inline-block;
    height: 2px;
    width: 6px;
    background-color: $gray-400;
    z-index: -1;
    border-radius: 8px;
    margin: 0 1px;
  }

  &--in_progress {
    background-color: lighten($gd-event-status-in-progress, 33);
    color: darken($gd-event-status-in-progress, 30);

    #{$parent}__position {
      background-color: $gd-event-status-in-progress;
      color: $white;
    }
  }

  &--completed {
    background-color: lighten($gd-event-status-completed, 40);
    color: darken($gd-event-status-completed, 10);

    #{$parent}__position {
      background-color: $gd-event-status-completed;
      color: lighten($gd-event-status-completed, 60);
    }

    & + #{$parent}__line {
      background-color: $gd-event-status-completed;
    }
  }

  &--suspended {
    background-color: lighten($gd-event-status-suspended, 38);
    color: darken($gd-event-status-suspended, 26);

    #{$parent}__position {
      background-color: $gd-event-status-suspended;
      color: lighten($gd-event-status-suspended, 60);
    }
  }

  &--cancelled,
  &--rejected {
    background-color: lighten($gd-event-status-cancelled, 35);
    color: darken($gd-event-status-cancelled, 26);

    #{$parent}__position {
      background-color: $gd-event-status-cancelled;
      color: lighten($gd-event-status-cancelled, 60);
    }
  }

  &--no-machine {
    #{$parent}__position {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        z-index: 0;
        right: -4px;
        bottom: -4px;
        height: 10px;
        width: 10px;
        background-color: $danger;
        border-radius: 100%;
        border: 2px solid $light;
      }
    }
  }
}
