.session-expiration {
  padding: .6rem 1.5rem;
  cursor: default;
  color: $secondary;
  display: flex;
  align-items: center;
  box-shadow: inset rgba($black, .03) 0 5px 15px;
  background-color: rgba($black, .01);

  &__icon {
    display: inline-block;
    text-align: center;
    width: 22px;
    margin-right: 10px;
  }
}
