.gd-browser-search {
  position: fixed;
  top: 0;
  right: 20%;

  padding: 8px 16px;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;
  border: 1px solid $gray-400;
  z-index: 999;

  transform: translateY(-100%);
  transition-property: transform;
  transition-duration: 300ms;

  &--open {
    transform: translateY(0);
    transition-duration: 300ms;
  }

  &__input {
    border: 0;
    outline: none;
  }

  &__matches {
    display: inline-block;
    border-right: 1px solid $gray-300;
    margin-right: $spacer;
    padding-right: $spacer;
  }
}
