.nav-tabs {
  background-color: $gray-300;
  border-radius: $border-radius;
  overflow: hidden;

  .nav-item {
    a.nav-link {
      cursor: pointer;
      color: $gray-600;
      background-color: transparent;
      border-radius: 0;
      font-weight: 500;
      font-size: 0.85rem;
      user-select: none;

      &.active {
        position: relative;
        color: $black;
        background-color: $gray-200;
      }
    }
  }

  &--container {
    position: relative;
    z-index: 900;
    background-color: transparent;

    .nav-item {
      .nav-link {
        &.active {
          position: relative;
          // box-shadow: rgba($black, .05) 0 -3px 15px;
          // border: 1px dashed $primary !important;
          // border-bottom-width: 0 !important;
          background-color: $gray-300 !important;
          color: $dark !important;
          border-radius: $border-radius $border-radius 0 0;

          // &:before {
          //   position: absolute;
          //   content: '';
          //   left: 50%;
          //   bottom: -20px;
          //   width: 100%;
          //   height: 20px;
          //   background-color: white;
          //   transform: translateX(-50%)
          // }
        }
      }
    }
  }
}
