.gd-card {
  @extend .p-3, .rounded, .shadow-lg, .w-100;
  display: block;
  background-color: $white;

  &__icon {
    @extend .me-2;

    opacity: 0.5;
  }

  &__title {
    font-size: 1.4em;
    font-weight: 300;
    color: $gray-800;
  }
}
