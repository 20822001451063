.gd-bubble {
  @extend .px-3, .py-2;

  position: fixed;
  bottom: $gd-bubble-margin;
  left: $gd-aside-width + $gd-bubble-margin;
  background-color: rgba($dark, .9);
  color: $white;
  border-radius: $border-radius;

  pointer-events: none;
  transform: translateY(150%);
  transition-duration: 500ms;
  z-index: 99;

  &--show {
    pointer-events: inherit;
    transform: translateY(0);
  }
}
