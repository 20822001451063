.gd-checkbox {
  cursor: pointer;
  display: flex;
  margin: 3px 0;

  &__indicator {
    color: $primary;
    border: 1px solid $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $spacer * $gd-checkbox-size;
    height: $spacer * $gd-checkbox-size;
    border-radius: $border-radius;
    font-size: 0.7em;
    color: transparent;
  }

  &__label {
    margin-left: calc($spacer / 2);
    line-height: 1.1rem;
    display: flex;
    align-items: center;
  }

  &--loading {
    .gd-checkbox__indicator {
      color: $primary;
    }
  }

  &--false-icon {
    .gd-checkbox__indicator {
      color: $primary;
    }
  }

  &--active {
    .gd-checkbox__indicator {
      background-color: $primary;
      color: $white;
    }
  }

  &--disabled {
    .gd-checkbox__indicator {
      cursor: not-allowed;
      border: 1px solid $gray-600;
      background-color: $gray-200;
    }
  }

  &--active.gd-checkbox--disabled {
    .gd-checkbox__indicator {
      background-color: $gray-600;
    }
  }
}
