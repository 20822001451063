.gd-key {
  display: inline-block;
  font-size: 0.55rem;
  font-family: $font-family-monospace;
  background-color: rgba($white, 0.6);
  padding: 2px 6px;
  color: $gray-600;
  font-weight: bold;
  border-radius: $border-radius;
  border-bottom: 2px solid rgba($black, 0.2);

  @include shadow($black);

  &--dark {
    background-color: rgba($black, 0.6);
    color: rgba($white, 0.7);
    border-bottom-color: rgba($black, 0.8);
  }
}
