@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'bootstrap';
@import '~bootstrap/scss/bootstrap';
@import 'mixins';
@import 'customize';
@import 'variables';
@import 'buttons';
@import 'forms';
@import 'layout';
@import 'components';
@import 'elements';
@import 'pages';
@import 'libs';
@import 'reset';

* {
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  body {
    overflow: hidden;
  }
}

a {
  text-decoration: none !important;
}

body {
  overflow-x: hidden;

  backface-visibility: hidden;
  background-color: theme('colors.slate.100');
}

.table-sticky {
  th[scope='col'] {
    position: sticky;
    top: -1px;
    z-index: 997;
  }
}

.filter-panel--active + .table-sticky--with-filter-panel {
  th[scope='col'] {
    @include media-breakpoint-up(md) {
      top: $gd-filter-panel-height - 1;
      position: sticky;
    }
  }
}

.table-bordered {
  thead {
    th,
    td {
      border-bottom-width: 1px;
    }
  }
}

.table-loading {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($light, 0.9);
    opacity: 0;
    visibility: hidden;
    transition-duration: 200ms;
    z-index: 10;
  }

  &.loading {
    &:before {
      visibility: visible;
      opacity: 1;
    }
  }
}

ul.pagination {
  margin-bottom: 0;
}

.pagination .page-link {
  cursor: pointer;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

time {
  cursor: default;
}

.gd-custom-scroll {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($light, 0.3);
  }

  &--dark {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($dark, 0.2);
    }
  }
}

.text-extramuted {
  color: $gray-500;
}

.font-monospace {
  font-family: $font-family-monospace;
}

.extrasmall {
  font-size: 0.6rem;
}

pre {
  border: 0;
  margin: 0;
  font-family: $font-family-base;
  overflow: visible;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.textarea-expanded {
  position: absolute;
  width: 320px;
  min-height: 60px;
  z-index: 21;
}

.textarea-not-expanded {
  max-height: 36px;
}

.text-dotted {
  border-bottom: 2px dotted $gray-500;
}

.cursor-default {
  cursor: default;
}

.tooltip-right-success::before {
  border-right-color: $success !important;
}

.placeholder-small::placeholder {
  @extend .small;
}

@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

div[role='progressbar'] {
  --size: 12rem;
  --fg: var(--tw-colors-green-500);
  --bg: var(--tw-colors-gray-200);
  --pgPercentage: var(--value);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(
      closest-side,
      white 60%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
}
