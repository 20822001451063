.sortable {
  cursor: pointer;
  position: relative;
  user-select: none;

  &--active {
    color: $primary;
  }

  &:before, &:after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    opacity: .5;
    font-size: .7em;
  }

  &:before {
    content: "\25b2";
  }
  &:after {
    content: "\25bc";
  }

  &--asc:before,
  &--desc:after {
    display: block;
  }
}
