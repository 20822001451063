.ReactVirtualized__Grid {
  border-radius: 0 0 $border-radius $border-radius;
  background-color: $gray-100;
}

.ReactVirtualized__Table__headerRow {
  background-color: $gray-300;
  border-radius: $border-radius $border-radius 0 0;
}

.ReactVirtualized__Table__headerColumn {
  position: relative;
  border: 0;
  font-size: 0.7em;
  font-weight: 600;
  color: $gray-600;
  letter-spacing: 0.02em;
  padding: 0.6rem 15px;
  vertical-align: text-bottom;
  display: flex;
  align-items: center;
  text-transform: none;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: $gray-500;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }
}

.ReactVirtualized__Table__row {
  position: relative;
  border-bottom: 1px solid $gray-200;
  background-color: $white;

  &.highlighted {
    &::after {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      // border: 1px solid rgba($warning, 0.7);
      background-color: rgba($warning, 0.1);
      border-radius: $border-radius;
      pointer-events: none;
      z-index: 0;
    }

    &--focus {
      &::after {
        border: 1px solid $warning;
        background-color: rgba($warning, 0.2);
      }
    }
    &--selected {
      &::after {
        border: 1px solid $primary;
        background-color: rgba($primary, 0.1);
      }
    }
  }

  &:hover {
    background-color: lighten($gray-200, 5);

    &::before {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border: 1px solid $gray-400;
      border-radius: $border-radius;
      pointer-events: none;
      z-index: 0;
    }
  }
}

.ReactVirtualized__Table__rowColumn {
  border-left: 1px solid $gray-200;
  padding: 10px;
  text-overflow: clip !important;

  &:first-child {
    border: 0;
  }
}
