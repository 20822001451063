.button-badge {
  position: relative;

  .badge {
    position: absolute;
    bottom: 0;
    right: 0;
    top: auto;
    transform: translate(25%, 25%);
  }

  &--circle {
    .badge {
      border-radius: 100%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--small {
    .badge {
      transform: translate(25%, 35%);
      width: 15px;
      height: 15px;
    }
  }
}
