.role {
  font-size: .8rem;
  font-weight: 500;
  display: inline-block;
  padding: 2px 10px;
  background-color: $light;
  color: darken($light, 60);
  border-radius: 2rem;

  &--admin {
    background-color: lighten($danger, 42);
    color: $danger;
  }

  &--customer_service {
    background-color: lighten($blue, 47);
    color: $blue;
  }
}
