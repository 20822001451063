@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700;900&display=swap');

$font-size-base: 0.9rem;
$font-family-sans-serif: 'Roboto', sans-serif;

$primary: #0d6efd;
$secondary: #48465b;
$black: #000000;
$dark: #1c2233;
$light: #f8f8f8;
$danger: #ff4539;
$success: #34c759;

$body-bg: $light;

$font-weight-medium: 500;
$enable-shadows: true !default;
// $input-box-shadow: none;

$shadow: darken($light, 10);
$box-shadow-sm: 0 2px 7px 0 rgba($shadow, 0.09);
$box-shadow: 0 0 14px 0 rgba($shadow, 0.12);
$box-shadow-lg:
  0 1.7px 2.2px rgba(0, 0, 0, 0.006),
  0 4.1px 5.3px rgba(0, 0, 0, 0.008),
  0 7.8px 10px rgba(0, 0, 0, 0.01),
  0 13.8px 17.9px rgba(0, 0, 0, 0.012),
  0 25.9px 33.4px rgba(0, 0, 0, 0.014),
  0 62px 80px rgba(0, 0, 0, 0.02);
$box-shadow-inset: inset 0 1px 2px rgba($shadow, 0.015) !default;

$modal-content-box-shadow-xs: $box-shadow-lg;
$modal-content-box-shadow-sm-up: $box-shadow-lg;
$modal-content-border-width: 0;
$modal-content-bg: white;

// $btn-border-width: 1p;
$btn-focus-box-shadow: none !important;
$btn-box-shadow: none;
$btn-active-box-shadow: none;

$dropdown-border-width: 0;
$dropdown-border-radius: 0;
$dropdown-box-shadow: $box-shadow-lg;
$dropdown-item-padding-y: 0.6rem;
$dropdown-padding-y: 0;

// $table-accent-bg: darken($light, 2);
$table-border-color: darken($light, 8);
$table-cell-padding: 0.6rem;
$table-accent-bg: transparent;
$table-body-bg: white;
$table-bg: transparent;

$alert-color-level: 1;
$dropdown-divider-margin-y: 0;

$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: lighten($primary, 47);

$navbar-padding-y: 0;
$nav-link-padding-y: 0.7rem;

$pagination-focus-outline: 0;
$pagination-focus-box-shadow: none;

$nav-tabs-link-active-bg: white;
$nav-tabs-border-width: 0;
$nav-tabs-link-active-color: $primary;

$popover-box-shadow: none;
$popover-border-color: #aaa;
$popover-bg: white;

$progress-height: 5px;
$progress-bg: #eee;
$progress-box-shadow: none;
