.gd-wrapper {
  display: grid;
  grid-template-columns: $gd-aside-width calc(100% - $gd-aside-width);
  grid-template-rows: auto;
  max-width: 100vw;

  .gd-aside {
    grid-column: 1;
    grid-row: 1;
  }

  &__content {
    grid-column: 2;
    grid-row: 1;
  }
}
