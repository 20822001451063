.filter-panel {
  $parent: &;

  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  z-index: 998;
  height: $gd-filter-panel-height;
  display: flex;
  align-items: center;
  position: relative;

  &--active {
    position: sticky;
    @include media-breakpoint-down(sm) {
      position: relative;
    }
  }

  &__content {
    width: 100%;
  }

  &__pin {
    position: absolute;
    right: -38px;
    width: 29px;
    overflow: hidden;

    .btn-light {
      position: relative;
      border-radius: 100%;
      color: darken($light, 5);
      box-shadow: none;
      transform: translateX(-5px);
      opacity: 0;
      transition: transform .3s, opacity .3s;
      background-color: #fff;
      border: 0;
      width: 29px;

      &.active {
        color: darken($light, 15) !important;
        background-color: $white !important;
      }

      &:hover {
        color: $dark;
      }

      &:hover,
      &.active {
        transform: rotate(45deg) !important;
      }
    }
  }

  &:hover,
  &--active {
    #{$parent}__pin {
      overflow: visible;

      .btn-light {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    left: 0;
    bottom: -1px;
    background-color: rgba($black, .1);
  }
}
