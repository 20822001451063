@keyframes rotate-device {
  from {
    transform: rotate(0deg);
    color: $secondary;
  }
  50%, 100% {
    color: $white;
    transform: rotate(-90deg);
  }
}

.orientation-lock {
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .orientation-lock {
    background-color: $black;
    z-index: 9999;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: $spacer * 2;

    &__icon {
      color: $primary;
      font-size: 4rem;
      animation: rotate-device 4s infinite;
    }

    &__text {
      text-transform: uppercase;
      font-weight: 600;
      font-size: .9em;
      letter-spacing: 0.03em;
    }
  }
}

