.navbar .megamenu {
  padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu {
    position: static !important;
  }
  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }

}
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media (max-width: 991px) {
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}
/* ============ mobile view .end// ============ */
