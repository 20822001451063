.empty-state {
  $parent: &;

  @extend .px-3, .py-4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $light;
  color: $secondary;
  border-radius: $border-radius;

  &--big {
    @extend .p-5;

    #{$parent}__header {
    }
  }

  &--full {
    background-color: #ffffff;
    background-image: radial-gradient(#dee2e6 0.5px, #ffffff 0.5px);
    background-size: 10px 10px;
    position: relative;
    height: 100%;
    z-index: 10;

    #{$parent}__header {
      font-size: 1.2em;
    }
  }
}
