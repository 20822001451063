.gd-maintenance {
  background-color: rgba($primary, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    max-width: 500px;
    color: $black;
    text-align: center;
    color: $white;
  }

  &__title {
    font-size: 2rem;
    font-weight: 400;
  }

  &__subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    color: rgba($white, 0.7);
  }
}
