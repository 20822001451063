@keyframes slidein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown {
  .dropdown-menu {
    outline: none;
    animation: slidein 0.5s;

    .dropdown-icon {
      display: inline-block;
      text-align: center;
      width: 22px;
      margin-right: 10px;
    }

    .dropdown-header {
      font-size: 0.7rem;
      text-transform: uppercase;
      padding-top: 5px;
      padding-bottom: 5px;
      outline: none;
      box-shadow: $light 0 10px 25px;
      color: $gray-600;
      cursor: default;
    }
  }

  &--small {
    .dropdown-menu {
      @extend .gd-custom-scroll, .gd-custom-scroll--dark;

      height: auto;
      overflow-y: auto;
      max-height: 60vh;
      box-shadow: rgba($dark, 0.1) 0 5px 10px;
      border-radius: 0 0 5px 5px;

      .dropdown-item {
        padding: calc($spacer / 4) calc($spacer / 1.5);
      }
    }
  }
}
