.gd-container {
  @extend .rounded, .m-2;
  background-color: $white;
  box-shadow: $box-shadow-lg;
  position: relative;

  &--tabs {
    margin: 0 !important;
    border-radius: 0 $border-radius $border-radius $border-radius !important;
    background-color: $gray-200;
    box-shadow: none !important;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &__head {
    @extend .px-4, .p-3;
    font-size: 1.1rem;
    font-weight: $font-weight-medium;
    color: $gray-700;
    background-color: lighten($gray-100, 1);
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--small {
      font-size: 0.9rem;
    }
  }

  &__body {
    @extend .p-4;
    @include media-breakpoint-down(sm) {
      padding: $spacer !important;
    }
  }

  & + & {
    @extend .mt-3;
  }

  &--sm {
    .gd-container__body {
      @extend .p-2;
    }
  }
}
