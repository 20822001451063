.gd-printing {
  table {
    border: 2px solid $black;

    tr:not(:last-child) {
      border-bottom: 2px solid $black;
    }

    tr td:not(:last-child),
    tr th:not(:last-child) {
      border-right: 2px solid $black;
    }

    tr td,
    tr th {
      padding: calc($spacer / 2);
      vertical-align: middle;
    }

    tr th {
      font-weight: 500;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
    }

    &.no-border {
      &,
      tr,
      tr th,
      tr td {
        border: none;
        padding: 0 5px;
      }
    }
  }
}
