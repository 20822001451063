@use 'sass:list';

$colors: 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet',
  'purple', 'fuchsia', 'pink', 'rose', 'gray', 'slate', 'primary';
$color-shades: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950;

:root {
  @each $color in $colors {
    @each $shade in $color-shades {
      --tw-colors-#{$color}-#{$shade}: theme('colors.#{$color}.#{$shade}');
    }
  }
}

$gd-menu-item-padding: 0.6rem;
$gd-menu-icon-margin: 0.8rem;
$gd-header-padding: 20px;
$gd-aside-width: 70px;
$gd-navbar-height: 45px;
$gd-heading-color: #595d6e;
$gd-light-accent: darken($light, 10);
$gd-filter-panel-height: 60px;
$gd-checkbox-size: 1.2;
$gd-bubble-margin: 5px;
