.gd-fullscreen-loading {
  background-color: $light;
  position: fixed;
  z-index: 9998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-500;
  font-size: 4em;
}
