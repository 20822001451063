.gd-info-table {
  &__row {
    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }
  }

  &__col {
    @extend .p-2, .px-3;
  }

  &__header {
    font-weight: bold;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  }
}
