$gd-user-badge-color: $gray-700;

.gd-user-badge {
  display: inline-flex;
  align-items: center;
  background-color: $gray-300;
  border-radius: $border-radius-pill;
  color: darken($light, 70);
  transition: $transition-base;
  font-weight: bold;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
    background-color: rgba(darken($light, 10), 0.9);

    .gd-user-badge__avatar {
      background-color: lighten($gd-user-badge-color, 20);
    }
  }

  &__avatar {
    background-color: lighten($gd-user-badge-color, 30);
    background-position: center;
    background-size: cover;
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    font-weight: bold;
    font-size: 0.8rem;
    transition: $transition-base;

    &--small {
      width: 20px;
      height: 20px;
      font-size: 0.7rem;
    }
  }

  &__name {
    @extend .ps-2, .pe-3;
    display: inline-block;
    font-size: 0.8rem;
  }
}

.gd-user-badge:not(:last-child) {
  @extend .me-1;
}
