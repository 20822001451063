$gd-select-small-height: 15px;

.gd-select {
  &--small {
    .gd-select__control {
      height: $gd-select-small-height;

      .gd-select__value {
        line-height: $gd-select-small-height !important;

        .gd-select__value-label {
          line-height: $gd-select-small-height;
        }
      }

      .gd-select__input {
        height: $gd-select-small-height;
      }
    }
  }

  &__placeholder {
    font-size: .7rem;
  }
}
