.sequence-number {
  $parent: &;

  background-color: $gray-600;
  display: inline-flex;
  border-radius: 5px;
  text-wrap: nowrap;
  overflow: hidden;
  cursor: pointer;

  &--compact {
    background-color: $gray-300;
    height: 20px;

    #{$parent}__number {
      color: $gray-600;
      padding: 0 8px 0 4px;
      line-height: 20px;
    }

    #{$parent}__year {
      background-color: $gray-200;
      line-height: 20px;

      &:after {
        background-color: $gray-200;
      }
    }
  }

  &__number {
    font-weight: bold;
    color: $white;
    padding: 0 10px 0 6px;
    line-height: 24px;
    border-radius: 5px 0 0 5px;
    height: 24px;
  }

  &__year {
    background-color: $gray-400;
    font-size: 0.6rem;
    height: 24px;
    color: var(--gray);
    line-height: 24px;
    box-shadow: rgba(0, 0, 0, 0.05) -2px 0 2px;
    position: relative;
    border-radius: 10px 0 0 0;
    z-index: 2;
    width: 25px;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      height: 120%;
      width: 7px;
      background-color: $gray-400;
      transform: rotate(20deg) translateX(-2px);
      z-index: -1;
    }
  }
}
