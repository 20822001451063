@import 'nav-tabs';

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.input-group {
  position: relative;
}

.input-group-clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  opacity: 0.3;
  transition-duration: 0.4s;

  &:hover {
    opacity: 1;
  }

  .btn,
  .btn:hover,
  .btn:focus,
  .btn.active {
    background-color: transparent;
    border: 0;
  }
}

.mw-0 {
  max-width: 0;
}

.bg-gray {
  background-color: $gray-500;
}

.nav-link {
  font-weight: 500;
  font-size: 0.85rem;
}
