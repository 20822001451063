@keyframes slidein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.gd-table,
.react-fluid-table {
  thead tr,
  .row-wrapper {
    th[scope='col'],
    .header-cell {
      position: relative;
      background-color: $white;
      border: 0;
      font-size: 0.8em;
      font-weight: 600;
      color: $gray-600;
      letter-spacing: 0.02em;
      padding: 0.6rem;
      vertical-align: text-bottom;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(
          0deg,
          rgba($gray-600, 0.2) 0%,
          rgba($gray-600, 0) 90%
        );
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      &.gd-header--vertical {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        font-weight: 500;
        text-align: right;
        font-size: 0.8em;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        height: 8px;
        background: linear-gradient(
          180deg,
          rgba($gray-700, 0.1) 0%,
          rgba($gray-600, 0) 90%
        );
        bottom: 0;
        border-top: 1px solid $gray-400;
        transform: translateY(100%);
      }
    }
  }

  tr.highlight {
    background: rgb(226, 226, 226);
    background: linear-gradient(
      90deg,
      rgba($warning, 0.1) 0%,
      rgba(255, 255, 255, 0) 20%
    );

    td:first-child {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        height: 100%;
        left: 0;
        top: 0;
        border-left: 3px solid $warning;
      }
    }
  }

  tr.danger {
    background: rgb(226, 226, 226);
    background: linear-gradient(
      90deg,
      rgba($danger, 0.1) 0%,
      rgba(255, 255, 255, 0) 20%
    );

    td:first-child {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        height: 100%;
        left: 0;
        top: 0;
        border-left: 3px solid $danger;
      }
    }
  }

  &__row {
    &--selectable {
      cursor: pointer;

      &.selected {
        position: relative;
        background-color: $gray-200;
      }
    }

    &--selection-highlight:not([class*='selected']) {
      background-color: rgba($gray-200, 0.6) !important;
    }
  }

  td.left-big-border {
    border-left-width: 2px;
  }
  td.right-big-border {
    border-right-width: 2px;
  }

  .sticky-header {
    position: sticky;
    z-index: 10;
  }

  .react-fluid-table-row-inner {
    overflow: hidden;

    &.selected {
      background-color: rgba($gray-200, 0.6) !important;
    }

    &[data-updating='true'] {
      position: relative;

      &::before {
        box-sizing: border-box;
        position: absolute;
        content: '';
        left: 1px;
        top: 1px;
        width: calc(100% - 1px);
        height: calc(100% - 1px);
        z-index: 0;
        background-color: lighten($orange, 40);
        border: 1px solid rgba($orange, 0.5);
        border-radius: $border-radius;

        animation: slidein 500ms;
      }
    }

    .cell {
      padding: 0.6rem;
      z-index: 1;
    }
  }

  &--info {
    tr:first-child {
      td,
      th {
        border-top: 0;
      }
    }
  }
}

.table-striped {
  .row-odd {
    background-color: darken($gray-100, 2);
  }
}

.table-thick-border {
  td,
  th {
    border: 3px solid black;
    border-collapse: collapse;
  }
}
