.orders-form-items {
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &--header {
      border-bottom: 1px solid $gray-300;
    }
  }

  &__col {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5px;

    &--id {
      width: 35px;
      height: 10px;
    }

    &--name {
      flex-grow: 1;
    }

    &--symbol {
      width: 24%;
    }

    &--external_identifier {
      width: 24%;
    }

    &--quantity {
      width: 115px;
    }

    &--actions {
      width: 30px;
    }
  }
}
