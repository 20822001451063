.btn {
  font-weight: 500;
}

.btn-xs {
  @include button-size(6px, 6px, 0.85em, 5px);
}
.btn-xxs {
  @include button-size(4px, 4px, 0.8em, 5px);
}

.btn-gray {
  @include button-variant(
    $background: $gray-300,
    $border: transparent,
    $hover-background: $gray-500
  );
}

.btn-outline-gray {
  @include button-variant(
    $background: transparent,
    $border: $gd-light-accent,
    $hover-background: $gd-light-accent,
    $hover-border: transparent
  );
  color: $dark;
}

.btn-accent-light {
  @include button-variant(
    $background: lighten($gd-light-accent, 5),
    $border: transparent,
    $hover-background: $primary
  );
}

.btn-accent-success {
  @include button-variant(
    $background: lighten($success, 45),
    $border: transparent,
    $hover-background: $success
  );
}

.btn-accent-danger {
  @include button-variant(
    $background: lighten($danger, 35),
    $border: transparent,
    $hover-background: $danger
  );
}

.btn-white {
  @include button-variant(
    $background: $white,
    $border: transparent,
    $hover-background: $primary
  );

  box-shadow: $box-shadow-sm;
}

.btn-neutral {
  @include button-variant(
    $background: $white,
    $border: transparent,
    $hover-background: $gray-400
  );

  box-shadow: $box-shadow-sm;
}

.btn-purple {
  @include button-variant(
    $background: #7e57c2,
    $border: transparent,
    $hover-background: #b085f5
  );
}

.btn-super-white {
  @include button-variant(
    $background: $white,
    $border: transparent,
    $hover-background: $white
  );

  box-shadow: $box-shadow-sm;

  &:hover {
    box-shadow: rgba($black, 0.07) 0 5px 10px;
  }
}

.btn-white-danger {
  @include button-variant(
    $background: $white,
    $border: transparent,
    $hover-background: $danger
  );

  box-shadow: $box-shadow-sm;
}

.btn-white-success {
  @include button-variant(
    $background: $white,
    $border: transparent,
    $hover-background: $success
  );

  box-shadow: $box-shadow-sm;
}
