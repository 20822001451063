@keyframes slidein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alert {
  // background-color: #fff;
  border-width: 0;
  box-shadow: $box-shadow-lg;
  animation: slidein 1s;

  .alert-icon {
    margin-right: 12px;
  }
}

.gd-alerts {
  box-sizing: border-box;
  position: fixed;
  top: 90px;
  right: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: calc((51px * 4) + 15px);
  overflow-y: scroll;
  padding: 15px;

  .alert {
    font-size: .85rem;
    margin: 4px;
    box-shadow: rgba($black, .1) 0 5px 15px, rgba($black, .05) -1px 1px 2px;
  }

  .alert:nth-last-child(5) {
    margin-bottom: 20px;
  }
}
