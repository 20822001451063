$gd-aside-lighten-color: $gray-800;

.gd-aside-spacer {
  width: $gd-aside-width;
  height: 100vh;
}

.gd-aside {
  background-color: theme('colors.slate.950');
  position: fixed;
  height: 100vh;
  width: $gd-aside-width;
  top: 0;
  left: 0;
  z-index: 999;

  &__header {
    height: $gd-navbar-height;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    text-align: center;
  }
}

.gd-aside-menu {
  $menu-parent: &;
  margin-top: $spacer;

  &__nav {
    margin: 0;
    padding: 0;
  }

  &__item {
    position: relative;

    #{$menu-parent}__link,
    #{$menu-parent}__expand {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: calc($spacer / 2) $spacer;
      color: theme('colors.slate.300');
      cursor: pointer;

      #{$menu-parent}__text {
        grid-column: 1;
        grid-row: 1;
        align-self: center;
        font-size: 0.65rem;
        font-weight: bold;
      }

      #{$menu-parent}__icon {
        grid-column: 2;
        grid-row: 1;
        align-self: center;
        justify-self: center;
        font-size: 1.2rem;
        color: theme('colors.slate.500');
        margin-bottom: 5px;
      }
    }

    &:hover #{$menu-parent}__link,
    &:hover #{$menu-parent}__expand,
    &:hover #{$menu-parent}__icon,
    #{$menu-parent}__link.active,
    #{$menu-parent}__expand.active,
    .active #{$menu-parent}__icon {
      color: $white;
      transition: color 200ms;
    }

    &.bottom-overflow {
      position: static;
    }

    #{$menu-parent}__expand {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: theme('colors.slate.800');
        z-index: -1;
        transform: translateY(-10px);
        opacity: 0;
      }

      #{$menu-parent}__arrow {
        position: absolute;
        top: 14px;
        right: calc($spacer / 2);
        font-size: 0.6rem;
        color: theme('colors.slate.600');
      }
    }

    &:hover #{$menu-parent}__expand::before {
      transform: translateY(0);
      opacity: 1;
      transition:
        transform 300ms,
        opacity 300ms;
    }

    .gd-aside-submenu {
      $submenu-parent: &;

      visibility: hidden;
      position: absolute;
      z-index: -1;
      top: 0;
      left: $gd-aside-width;
      background-color: theme('colors.slate.800');
      width: 250px;

      margin: 0;
      padding: 0;
      list-style: none;

      transform: translateY(-10px);
      transition: transform 300ms;

      #{$menu-parent}__item a {
        display: grid;
        grid-template-columns: auto 40px;
        grid-template-rows: auto;
        padding: calc($spacer / 1.5) $spacer;
        color: theme('colors.slate.400');

        #{$menu-parent}__icon {
          align-self: center;
          justify-self: center;
          grid-column: 2;
          grid-row: 1;
          text-align: center;
          margin-left: $spacer;
          opacity: 0.5;
        }

        #{$menu-parent}__text {
          grid-column: 1;
          grid-row: 1;
          font-size: 0.85rem;
        }

        &:hover,
        &.active {
          color: $white;
          background-color: theme('colors.slate.700');
          transition:
            color 300ms,
            background-color 300ms;
        }
      }

      &.expanded {
        visibility: visible;
        transform: translateY(0);
      }
    }

    &.bottom-overflow .gd-aside-submenu {
      top: inherit;
      bottom: 0;
    }
  }
}
