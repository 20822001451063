$gd-order-status-in-progress: $warning;
$gd-order-status-awaiting-for-approval: $dark;
$gd-order-status-completed: $success;
$gd-order-status-stored: rgb(8, 147, 145);
$gd-order-status-sent: rgb(12, 105, 148);
$gd-order-status-cancelled: $danger;
$gd-order-status-rejected: #895fff;

.gd-orders {
  &__row {
    display: flex;
    height: 100%;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 50%;
      height: 100%;
      opacity: 0;
      transform: translateX(-100%);
      transition-duration: 200ms;

      background-image: linear-gradient(
        90deg,
        transparent 0px,
        lighten($warning, 35) 50%,
        transparent 100%
      );
    }

    &--header {
      height: auto;
      background-color: $gray-300;
      border-radius: $border-radius $border-radius 0 0;
      font-size: 0.7em;
      font-weight: 600;
    }

    &--body {
      border-bottom: 1px solid $gray-200;
    }

    &--updating {
      transition-duration: 500ms;
      animation: shake 0.2s linear;

      &:before {
        opacity: 1;
        transform: translateX(-50%);
        transition-duration: 200ms;
      }

      .sequence-number {
        background-color: $warning;

        &__year,
        &__year:after {
          background-color: lighten($warning, 20);
        }
      }
    }
  }

  &__col {
    position: relative;
    padding: 3px;
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    padding: 5px;

    &--grow {
      flex-grow: 1;

      & > div {
        flex-grow: 1;
      }
    }

    &--body {
      align-items: center;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: $gray-200;
    }

    &--header {
      color: $gray-600;
      display: flex;
      align-items: flex-end;
      padding: 5px 10px;

      & > div {
        flex-grow: 1;
      }

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background-color: $gray-500;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }
    }

    &--event_type {
      width: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &--order_number {
      width: 85px;
    }

    &--contractor {
      width: 120px;
    }

    &--title {
      width: 240px;
    }

    &--status {
      width: 100px;
    }

    &--quantity {
      width: 70px;
    }

    &--date {
      width: 60px;
    }

    &--due_date {
      width: 73px;
    }

    &--price {
      width: 100px;
    }

    &--actions {
      justify-content: flex-end;
      flex-grow: 1;
    }

    &--header-vertical {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      font-weight: 500;
      text-align: right;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      background-color: $gray-400;
      max-height: 80px;
      overflow: hidden;
      line-height: 10px;
    }
  }
}

.order {
  &__item {
    @extend .small;

    &:not(:last-child) {
      @extend .mb-1, .pb-1;

      border-bottom: 1px solid $gray-300;
    }
  }

  &__status {
    display: inline-block;
    padding: 4px;
    background-color: $gray-600;
    color: $gray-100;
    font-size: 0.8em;
    font-weight: bold;
    border: 0;
    border-radius: $border-radius;
    letter-spacing: 0.03em;
    width: 90px;
    text-align: center;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;

    &:focus {
      outline: 0;
    }

    &--compact {
      width: 40px;
      height: 18px;
    }

    &--small {
      font-size: 0.7rem;
      padding: 2px;
      width: 80px;
    }

    &--awaiting_for_approval {
      background-color: $gd-order-status-awaiting-for-approval;
    }

    &--in_progress {
      background-color: $gd-order-status-in-progress;
      color: $white;
    }

    &--completed {
      background-color: $gd-order-status-completed;
      color: lighten($gd-order-status-completed, 60);
    }

    &--stored {
      background-color: $gd-order-status-stored;
      color: $white;
    }

    &--sent {
      background-color: $gd-order-status-sent;
      color: $white;
    }

    &--cancelled {
      background-color: $gd-order-status-cancelled;
      color: lighten($gd-order-status-cancelled, 60);
    }

    &--rejected {
      background-color: $gd-order-status-rejected;
      color: lighten($gd-order-status-rejected, 60);
    }
  }
}

@keyframes shake {
  0% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}
