$gd-bottom-navigation-margin: 5px;

.gd-bottom-navigation {
  $parent: &;

  position: fixed;
  bottom: 0;
  left: $gd-aside-width;
  width: calc(100% - #{$gd-aside-width});
  display: flex;
  justify-content: center;
  transform: translateY(100%);
  transition-duration: 300ms;
  transition-property: transform;
  user-select: none;
  pointer-events: none;
  opacity: 0;

  &__content {
    position: relative;
    box-sizing: border-box;
    background-color: rgba($white, 0.4);
    padding: 10px;
    border-radius: $border-radius;
    background-blend-mode: overlay;
    z-index: 2;
    margin: 8px;
    pointer-events: painted;

    @include shadow($black);
  }

  &__overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    z-index: 1;
    transform: translateY(100%);
    transition-duration: 300ms;
    transition-property: transform;

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &--available {
    opacity: 1;
    transform: translateY(calc(100% - 10px));

    #{$parent}__overlay {
      transform: translateY(0);
    }

    #{$parent}__content {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: -5px;
        width: 50px;
        height: 10px;
        margin: 0 auto;
        border-radius: 8px 8px 0 0;
        background-color: $black;
        z-index: -1;
        transition-duration: 300ms;
        transition-property: transform;
      }
    }
  }

  &--open {
    transform: translateY(0);

    #{$parent}__overlay {
      transform: translateY(0);
    }

    #{$parent}__content {
      &::before {
        transform: translateY(100px);
      }
    }
  }
}
