.gd-navbar {
  height: $gd-navbar-height;
  border-bottom: 1px solid theme('colors.slate.200');
  // background-color: $white;
}

.gd-navbar-online {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;

  &__count {
    position: absolute;
    bottom: 7px;
    right: 7px;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $success;
    border-radius: 100%;
    font-size: 0.65rem;
    font-weight: bold;
  }
}

.gd-navbar-users-online {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #fff;
  z-index: 9999;
  box-shadow: $box-shadow-lg;
  border-left: 1px solid $gray-200;
  padding: 12px;
  pointer-events: none;
  transform: translateX(100%);
  transition: transform 0.3s ease-in;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &--open {
    pointer-events: all;
    transform: translateX(0);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 0.04rem;
    color: $gray-600;
  }

  &__close-button {
    width: 30px;
    height: 30px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__container {
    overflow-y: auto;
  }

  &__user {
    padding: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__avatar {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      background-color: $success;
      border-radius: 100%;
      bottom: 0;
      right: -2px;
      border: 2px solid $white;
    }
  }

  &__name {
    font-weight: 500;
    color: $gray-700;
    font-size: 0.85rem;
  }

  &__time {
    font-size: 0.7rem;
    color: $gray-600;
  }
}
