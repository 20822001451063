.permissions {
  padding: 0 10px;
  &__item {
    @extend .row;
    padding: 8px 2px;
    background-color: $light;
    border-radius: $border-radius;
    opacity: .4;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &--active {
      opacity: 1;
    }
  }

  &__checkbox {
    margin-right: 12px;
  }
}
