.gd-page-header {
  margin-bottom: calc($spacer / -2);
  display: flex;
  @extend .px-3;

  &--md {
    @extend .py-3, .px-2;
  }

  &--sm {
    @extend .py-2;
  }

  &__title {
    margin-bottom: 0;
    margin-right: $spacer;
    font-size: 1.4rem;
    color: $secondary;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    justify-content: flex-end;
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;

    &-separator {
      @extend .rounded-pill;
      margin: 0 calc($spacer / 3);
      background-color: rgba($dark, 0.2);
      width: 4px;
      height: 4px;
    }

    &-item,
    &-item--active,
    &-item--home {
      color: rgba($dark, 0.6);
      font-size: 0.9em;
      font-weight: 500;
      transition: $transition-base;
    }

    &-item--home {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}
