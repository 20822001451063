$gd-material-status-ordered: $primary;
$gd-material-status-supplied: $success;
$gd-material-status-cancelled: $danger;

.material {
  &__status {
    position: relative;
    display: inline-block;
    padding: 2px 6px;
    background-color: $gray-600;
    color: $gray-100;
    font-size: 0.85em;
    border-radius: $border-radius;
    letter-spacing: 0.03em;
    width: 100px;
    text-align: center;
    box-shadow: rgba($black, 0.05) -1px 2px 10px;
    border: 0;

    &:focus {
      outline: 0;
    }

    &--ordered {
      background-color: $gd-material-status-ordered;
      color: lighten($gd-material-status-ordered, 50);
    }

    &--supplied {
      background-color: $gd-material-status-supplied;
      color: lighten($gd-material-status-supplied, 60);
    }

    &--cancelled {
      background-color: $gd-material-status-cancelled;
      color: lighten($gd-material-status-cancelled, 60);
    }

    &--forbidden {
      cursor: not-allowed;
    }

    &--compact {
      width: 20px;
      height: 20px;

      .material__status-content {
        display: none;
      }
    }
  }
}
