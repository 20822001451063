.gd-description {
  @extend .p-3;
  background-color: $gray-100;
  color: $gray-700;
  border-radius: $border-radius;

  font-family: $font-family-monospace;
  font-size: 0.75rem;

  &--small {
    padding: 10px !important;
    font-size: 0.6rem;
  }
}
